/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/


.nav {
    position: relative;
    width: 100%;
    height: 100%;
    background: rgb(135, 124, 200);
    background: -o-linear-gradient(338deg, rgba(135, 124, 200, 1) 20%, rgba(156, 133, 198, 1) 41%);
    background: linear-gradient(112deg, rgba(135, 124, 200, 1) 20%, rgba(156, 133, 198, 1) 41%);
    padding: 0 10px;
    border-radius: 16px;
    -webkit-box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.288);
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.288);
}

.nav .brand {
    position: relative;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    background-image: -webkit-gradient(linear, left top, right top, from(#d9d2ff), color-stop(#cec8e9), to(#a8dcff));
    background-image: -o-linear-gradient(left, #d9d2ff, #cec8e9, #a8dcff);
    background-image: linear-gradient(to right, #d9d2ff, #cec8e9, #a8dcff);
    font-size: 26px;
    white-space: nowrap;
    cursor: pointer;
    -webkit-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    transition: 0.3s ease;
    -webkit-tap-highlight-color: transparent;
}

.nav .brand:hover {
    scale: 1.05;
}

@media (max-width: 400px) {
    .nav .brand {
        font-size: 140%;
    }
}

.subBrand {
    position: absolute;
    bottom: -12px;
    letter-spacing: 1px;
    right: 0px;
    color: rgb(255, 255, 255);
    font-size: 12px;
}

.nav .menu {
    height: 40px;
    width: 50px;
    border-radius: 12px;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.153);
    position: relative;
    cursor: pointer;
    -webkit-transition: 0.5s ease;
    -o-transition: 0.5s ease;
    transition: 0.5s ease;
    -webkit-tap-highlight-color: transparent;
}

.nav .menu:hover,
.menu.active {
    background: rgba(255, 255, 255, 0.284);
}

.menu .bar {
    height: 2px;
    width: 40px;
    border-radius: 5px;
    background: white;
    position: absolute;
    right: 5px;
    -webkit-transition: 0.5s cubic-bezier(1, .02, .1, .89);
    -o-transition: 0.5s cubic-bezier(1, .02, .1, .89);
    transition: 0.5s cubic-bezier(1, .02, .1, .89);
}

.menu .bar1 {
    top: 11px;
}

.menu .bar2 {
    top: 19.5px;
    width: 25px;
}

.menu .bar3 {
    top: 28px;
    width: 32px;
}

.menu.active .bar1 {
    height: 4px;
    width: 28px;
    top: 50%;
    right: 50%;
    -webkit-transform: translate(50%, -50%) rotate(45deg);
    -ms-transform: translate(50%, -50%) rotate(45deg);
    transform: translate(50%, -50%) rotate(45deg);
}

.menu.active .bar2 {
    right: 50px;
}

.menu.active .bar3 {
    height: 4px;
    width: 28px;
    top: 50%;
    right: 50%;
    -webkit-transform: translate(50%, -50%) rotate(-45deg);
    -ms-transform: translate(50%, -50%) rotate(-45deg);
    transform: translate(50%, -50%) rotate(-45deg);
}


.drawer {
    height: calc(100dvh - 100px);
    width: 280px;
    position: absolute;
    background: rgba(155, 150, 215, 0.772);
    background: -o-linear-gradient(140deg, rgba(155, 150, 215, 0.7) 0%, rgba(148, 188, 233, 0.711) 100%);
    background: linear-gradient(310deg, rgba(155, 150, 215, 0.7) 0%, rgba(148, 188, 233, 0.711) 100%);
    top: 70px;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    right: calc(-110%);
    border-radius: 12px;
    z-index: 1;
    opacity: 0;
    -webkit-transition: 0.5s ease;
    -o-transition: 0.5s ease;
    transition: 0.5s ease;
    border: 2px solid rgba(128, 121, 248, 0.373);
    overflow: hidden;
    visibility: hidden;
    transform: translate3d(0,0,0);
}

.drawer.active {
    right: 0px;
    opacity: 1;
    visibility: visible;
}





.TheDrawer {
    height: 100%;
    width: 100%;
    padding: 10px;
}

.info .name {
    color: rgb(93, 63, 189);
}

.info .designation {
    font-weight: 400;
    font-size: 16px;
}

.info .designation h4 {
    font-weight: 600;
    font-size: 14px;
    padding: 10px 0;
}

.info .email {
    padding: 10px 0;
    font-family: monospace;
}

.background {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: -1;
    background: rgba(0, 0, 0, 0.416);
    visibility: hidden;
    top: 0;
    left: 0;
    opacity: 0;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.background.active {
    visibility: visible;
    opacity: 1;
}