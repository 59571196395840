/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.modal {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.556);
    z-index: 99999;
}

.modal-body {
    min-height: 400px;
    max-height: 80vh;
    max-width: 700px;
    width: calc(100% - 40px);
    background: rgb(169, 154, 255);
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 12px;
    padding: 15px;
    overflow-y: scroll;
}

.modal-body .close {
    position: fixed;
    top: 10px;
    right: 10px;
    height: 25px;
    width: 25px;
    cursor: pointer;
    border: none;
    border-radius: 100px;
    font-size: 22px;
    background: rgb(120, 116, 255);
    color: white;
    -webkit-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    transition: 0.3s ease;
    z-index: 99;
}

.modal-body .close:hover {
    background: #ffffff;
    color: black;
}