/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.forgotPass {
    position: relative;
    z-index: 6;
    min-height: 600px;
    display: -ms-grid;
    display: grid;
    place-items: center;
}

.svg {
    height: 600px;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.forgotPass .content {
    width: 100%;
    max-width: 400px;
    padding: 20px;
    background: rgba(0, 0, 0, 0.337);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 40px;
    border-radius: 8px;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    z-index: 3;
}

.content .h1 {
    color: rgb(242, 234, 255);
}

.content>form {
    width: 100%;
}

.content>form>div>input {
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 6px;
    padding: 0 15px;
    font-size: 14px;
    font-weight: bold;
    color: black;
    background: rgba(255, 255, 255, 0.922);
    font-family: var(--poppins);
}

.content>form>input[type='submit'] {
    background: rgb(177, 237, 99);
    border-radius: 8px;
    font-weight: bold;
    color: black;
    display: block;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin: 0 auto;
    margin-top: 15px;
    padding: 8px 25px;
    cursor: pointer;
    border: none;
    -webkit-tap-highlight-color: transparent;
}

p.message {
    color: white;
    font-size: 18px;
    font-family: var(--poppins);

}

.resend {
    padding: 4px 10px;
    border-radius: 100px;
    border: none;
    font-weight: bold;
    background: rgb(138, 99, 255);
    color: white;
    -webkit-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    transition: 0.3s ease;
}

.resend:hover {
    background: white;
    color: rgb(138, 99, 255);
}

.resend:disabled {
    background: rgb(96, 96, 96);
    color: rgb(173, 173, 173);
}

.exceptions {}

.exception {
    color: white;
    font-family: var(--poppins);
    font-size: 12px;
}

.exception.hidden {
    display: none;
}