@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

:root {
  --poppins: "Poppins", sans-serif;
  --fira: "Fira Sans Condensed", sans-serif;
}


#main {
  height: 100dvh;
  width: 100%;
  position: relative;
  padding-top: 70px;
  overflow-x: hidden;
  scroll-behavior: smooth;
  font-family: var(--fira);
  background: rgba(224, 224, 224, 0.666);
}

#loader {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.363);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  opacity: 0;
  visibility: hidden;
  z-index: 99999;
  -webkit-transition: 0.5s all ease;
  -o-transition: 0.5s all ease;
  transition: 0.5s all ease;
}

/* #loader::before {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: -100%;
  background: rgba(119, 121, 178, 0.625);
  -webkit-transition: 1s all cubic-bezier(1, .02, .1, .89);
  -o-transition: 1s all cubic-bezier(1, .02, .1, .89);
  transition: 1s all cubic-bezier(1, .02, .1, .89);
  -webkit-clip-path: polygon(0 0, 0 100%, 50% 100%, 50% 80%, calc(50% + 50px) calc(80% - 35px), calc(50% + 50px) calc(50% + 25px), calc(50% - 50px) calc(50% - 25px), calc(50% - 50px) calc(20% + 35px), 50% 20%, 50% 0);
  clip-path: polygon(0 0, 0 100%, 50% 100%, 50% 80%, calc(50% + 50px) calc(80% - 35px), calc(50% + 50px) calc(50% + 25px), calc(50% - 50px) calc(50% - 25px), calc(50% - 50px) calc(20% + 35px), 50% 20%, 50% 0);
}

#loader::after {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: -100%;
  background: rgba(102, 116, 224, 0.714);
  -webkit-transition: 1s all cubic-bezier(1, .02, .1, .89);
  -o-transition: 1s all cubic-bezier(1, .02, .1, .89);
  transition: 1s all cubic-bezier(1, .02, .1, .89);
  -webkit-clip-path: polygon(100% 0, 100% 100%, 50% 100%, 50% 80%, calc(50% + 50px) calc(80% - 35px), calc(50% + 50px) calc(50% + 25px), calc(50% - 50px) calc(50% - 25px), calc(50% - 50px) calc(20% + 35px), 50% 20%, 50% 0);
  clip-path: polygon(100% 0, 100% 100%, 50% 100%, 50% 80%, calc(50% + 50px) calc(80% - 35px), calc(50% + 50px) calc(50% + 25px), calc(50% - 50px) calc(50% - 25px), calc(50% - 50px) calc(20% + 35px), 50% 20%, 50% 0);
}

#loader.active::before {
  left: 0;
}

#loader.active::after {
  right: 0;
} */


#loader.active {
  opacity: 1;
  visibility: visible;
}

#loader .spin {
  position: absolute;
  height: 100px;
  width: 100px;
  border: 5px solid rgba(255, 255, 255, 0.87);
  border-radius: 100px;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transition: 0.8s ease;
  -o-transition: 0.8s ease;
  transition: 0.8s ease;
  -webkit-transform: translate(-50%, -50%) rotate(0deg) ;
  -ms-transform: translate(-50%, -50%) rotate(0deg) ;
  transform: translate(-50%, -50%) rotate(0deg) ;
  border-top-color: transparent;
  border-bottom-color: transparent;
  z-index: 100;
}

#loader.active .spin {
  opacity: 1;
  -webkit-animation: spin 1s linear 0s infinite;
  animation: spin 1s linear 0s infinite;
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes spin {
  to {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

#container {}

#header {
  height: 60px;
  width: calc(100% - 20px);
  top: 10px;
  left: 10px;
  position: fixed;
  z-index: 9999;
}

#toast {
  position: fixed;
  height: auto;
  max-width: 400px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  gap: 10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: 0.5s all ease;
  -o-transition: 0.5s all ease;
  transition: 0.5s all ease;
  top: 80px;
  z-index: 999999;
}

.toast {
  -webkit-transform: translateX(-20px);
  -ms-transform: translateX(-20px);
  transform: translateX(-20px);
  background-color: #ffffff;
  width: calc(100% - 40px);
  color: #fff;
  padding: 16px;
  border-radius: 8px;
  opacity: 0;
  -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.351);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.351);
  -webkit-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  transition: 0.5s ease;
  /* Transition for left and opacity changes */
}

.toast.active {
  opacity: 1;
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.toast.active.remove {
  -webkit-transform: translateX(40px);
  -ms-transform: translateX(40px);
  transform: translateX(40px);
  opacity: 0;
}

/* Apply different styles for different toast types (success, error, etc.) if needed */
.toast.success {
  background-color: #98c7a4;
}

.toast.error {
  background-color: #dc3545;
}


#body {
  width: calc(100% - 40px);
  max-width: 700px;
  background: #ffffff;
  min-height: calc(100vh - 100px);
  border-radius: 16px;
  -webkit-box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.068);
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.068);
  margin: 0 auto;
  margin-top: 10px;
  padding: 20px;
}





.fancyForm {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px;
  gap: 20px;
  border-radius: 12px;
  background: rgb(226, 221, 255);
  outline: 1px solid rgba(178, 148, 255, 0.408);
  -ms-flex-item-align: start;
  align-self: flex-start;
  width: 100%;
  min-width: 200px;
  -webkit-box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.132);
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.132);
}

.fancyForm>div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.fancyForm>div input,
.fancyForm>div select,
.fancyForm>div>textarea {
  height: 40px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.233);
  background: rgb(247, 247, 247);
  border-radius: 8px;
  padding: 10px;

  font-family: var(--poppins);
  font-size: 14px;
  color: midnightblue;
}

.fancyForm>div label {
  color: #474551;
  text-align: left;
  font-family: var(--poppins);
  font-size: 14px;
  text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.226);
}

.fancyForm input[type="submit"] {
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  max-width: 300px;
  border-radius: 8px;
}

.fancyForm>h2 {
  color: #00000075;
}

.fancyForm select option {
  padding: 10px;

}

.fancyForm>div>textarea {
  height: 80px;
}

.frontbtns {
  margin: 0 auto;
  margin-top: 100px;
  width: calc(100% - 80px);
}

@media (max-width: 400px) {
  .frontbtns {
    margin-top: 80px;
    width: calc(100% - 20px);
  }
}

.btn {
  border: none;
  background: white;
  padding: 8px 20px;
  border-radius: 6px;
  font-family: var(--fira);
  font-size: 18px;
  -webkit-transition: 0.7s ease;
  -o-transition: 0.7s ease;
  transition: 0.7s ease;
  cursor: pointer;
}

.btn.admin {
  background: #e0daff;
  font-size: 14px;
  color: rgb(0, 0, 0);
  letter-spacing: 0.5px;
}

.btn.admin:hover {
  background: #5f50ae;
  color: white;
}

.btn.admin.active {
  background: #7645f2;
  color: white;
  font-size: 16px;
  letter-spacing: 1px;
}

.btn.front {
  max-width: 250px;
  min-width: 200px;
  background: #816ced;
  color: #d6ceff;
  font-weight: 600;
  -webkit-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  transition: 0.5s ease;
}

.btn.loginhere {
  font-size: 18px;
  padding: 2px 10px;
  color: blue;
  font-weight: 600;
}

.btn.front:hover {
  letter-spacing: 1px;
  background: #5b4ca6;
  color: white;
}

.btn.logout {
  background: rgb(53, 37, 37);
  color: #ded8ff;
}

.btn.about {
  background: #56702d;
  color: rgba(255, 255, 255, 0.64);
}

.btn.about:hover {
  background: #c5ff69;
  color: rgba(0, 0, 0, 0.64);
}

.btn.logout:hover {
  background: #ded8ff;
  color: rgb(53, 37, 37);
}


.pd-x-5 {
  padding: 0 5px;
}

.pd-x-10 {
  padding: 0 10px;
}

.pd-y-5 {
  padding: 5px 0;
}

.pd-y-10 {
  padding: 10px 0;
}

.form-error {
  color: rgb(254 107 62);
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.x-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.columnRev {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.space-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.flex.g5 {
  gap: 5px;
}

.flex.g10 {
  gap: 10px;
}

.flex.g15 {
  gap: 15px;
}

.flex.g20 {
  gap: 20px;
}

.flex .f1 {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.flex .f2 {
  -webkit-box-flex: 2;
  -ms-flex: 2;
  flex: 2;
}

.flex .f3 {
  -webkit-box-flex: 3;
  -ms-flex: 3;
  flex: 3;
}

.flex .f4 {
  -webkit-box-flex: 4;
  -ms-flex: 4;
  flex: 4;
}

.flex .f5 {
  -webkit-box-flex: 5;
  -ms-flex: 5;
  flex: 5;
}

.flex .f6 {
  -webkit-box-flex: 6;
  -ms-flex: 6;
  flex: 6;
}

.capitalize {
  text-transform: capitalize;
}

.lowercase {
  text-transform: lowercase;
}

.uppercase {
  text-transform: uppercase;
}

.relative {
  position: relative;
}

.desc {
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.desc2 {
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.desc3 {
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}