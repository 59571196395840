/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/



.singleuser {
    padding: 20px 0;
}

.singleuser_name {
    font-size: 32px;
    font-weight: 700;
    color: rgb(84, 84, 255);
}

.singleuser_designation {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    padding-top: 5px;
    color: rgb(82, 81, 83);
}

.singleuser_designation .dept {
    font-weight: 400;
    font-size: 16px;
    display: block;
    color: rgb(33, 33, 33);
    padding: 10px 0;
}

.singleuser_email {
    text-align: right;
}

.singleuser_username {}

.twoTable {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 20px;
    font-size: 14px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.dtcontainer {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    width: 100%;
    min-width: 250px;
    margin-top: 10px;
}

.dtcontainer .head {
    text-align: center;
    border-radius: 12px 12px 0 0;
    background: rgb(193, 179, 218);
    padding: 7px;
    font-weight: 600;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.gapRatio {}

.gapRatio>div {
    padding: 7px 1px;
    text-align: center;
    background: rgb(200, 204, 220);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.gapRatio>div:not(:last-child) {
    border-right: 1px solid black;
}

.gapRatio:last-child {
    border-radius: 0 0 12px 12px;
    overflow: hidden;
}

.gapRatio.h>div {
    padding: 3px 1px;
    font-weight: 600;
    color: rgb(141, 93, 243);
}

.gapRatio .edit {
    background: rgb(133, 105, 212);
    border-radius: 4px;
    padding: 4px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: 0.4s ease;
    -o-transition: 0.4s ease;
    transition: 0.4s ease;
    cursor: pointer;
}

.gapRatio .edit:hover {
    background: rgb(98, 77, 157);
}


.addnew {
    font-weight: 400;
    font-size: 12px;
    background: rgb(159, 128, 237);
    border-radius: 4px;
    color: white;
    padding: 4px 10px;
    cursor: pointer;
    -webkit-transition: 0.4s ease;
    -o-transition: 0.4s ease;
    transition: 0.4s ease;
}

.addnew:hover {
    background: rgb(131, 105, 195);
}

.dtcontainer .flex.column {
    row-gap: 2px;
}

.gapRatio:hover:not(:first-child):not(:nth-child(2))>div {
    background: rgba(225, 225, 225, 0.686);
}


.one>div:last-child {
    -ms-flex-item-align: start;
    align-self: flex-start;
}

.dess {
    width: 100%;
}


.ulList {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 10px;

}

.ulList>li {
    padding: 10px;
    border-radius: 12px;
}

@media (max-width: 750px) {
    .one {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .singleuser_email {
        text-align: left;
    }
}