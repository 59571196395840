/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.AddADept {
    padding: 20px 0;
    text-align: center;
}

.AddADept>h2 {
    padding-bottom: 10px;
}

.AddADept .fancyForm {
    position: sticky;
    top: 10px;
}

@media(max-width: 500px) {
    .AddADept .fancyForm {
        position: relative;
    }
}

.deptcards {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    padding-top: 10px;
}

.deptcard {
    background: rgba(175, 173, 173, 0.218);
    border: 1px solid rgba(128, 128, 128, 0.28);
    padding: 10px 15px;
    border-radius: 8px;
    min-width: 200px;
    width: 100%;
    -webkit-transition: 0.2s ease;
    -o-transition: 0.2s ease;
    transition: 0.2s ease;
}

.deptcard p {
    font-size: 12px;
    text-align: left;
}

.deptcard:hover {
    background: rgba(175, 173, 173, 0.421);
}

.deptid h1 {
    font-size: 18px;
    color: rgb(81, 60, 189);
}

.deptid h3 {
    font-size: 18px;
}

.officecards {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 10px;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    padding-top: 10px;
}

.officecard {
    background: rgba(175, 173, 173, 0.218);
    border: 1px solid rgba(128, 128, 128, 0.28);
    padding: 10px 15px;
    border-radius: 8px;
    width: 100%;
    min-width: 200px;
    -webkit-transition: 0.2s ease;
    -o-transition: 0.2s ease;
    transition: 0.2s ease;
}

.officecard>div>h1 {
    font-size: 16px;
    text-align: left;
    text-transform: capitalize;
}

.officecard>div>p {
    font-size: 14px;
    text-align: left;
    text-transform: capitalize;
}

.ctrl {
    position: relative;
    overflow: hidden;
}

.ctrl .edit,
.ctrl .delete {
    height: calc(100% - 10px);
    position: absolute;
    top: 5px;
    width: calc(50% - 7.5px);
    border-radius: 6px;
    color: white;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    cursor: pointer;
    -webkit-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    transition: 0.3s ease;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 3px;

}

.ctrl:hover .edit {
    left: 5px;
}

.ctrl:hover .delete {
    right: 5px;
}

.ctrl .edit {
    background: rgba(0, 0, 255, 0.8);
    left: -50%;
}

.ctrl .delete {
    right: -50%;
    background: rgba(255, 0, 0, 0.8);
}

.ctrl .edit:hover {
    background: rgba(0, 0, 127, 0.8);
}

.ctrl .delete:hover {
    background: rgba(128, 0, 0, 0.8);
}

.officecard:hover {
    background: rgba(175, 173, 173, 0.421);
}

.select-type {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 5px !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    background: white;
    border-radius: 12px;
    padding: 3px;
}

.select-type .option {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 50px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 18px;
    cursor: pointer;
    font-family: var(--poppins);
    border-radius: 8px;
    -webkit-transition: .3s ease;
    -o-transition: .3s ease;
    transition: .3s ease;
    -webkit-tap-highlight-color: transparent;

}

.select-type .option:hover {
    color: midnightblue;
    background: rgba(0, 0, 0, 0.149);
}

.select-type .option.active,
.select-type .option.active:hover {
    font-size: 22px;
    background: rgb(116, 116, 127);
    color: white;
}




.deleteOffice p {
    text-align: left;
    padding: 5px;
}

.deleteOffice>h2 {
    padding: 10px 0;
    color: #dad3ff;
    text-shadow: 0px -1px 2px #0000005e;
}

a {
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
}

.head2 {
    color: rgb(255, 255, 255);
    padding: 10px 5px;
    background: #8d76ff;
    border-radius: 8px;
}

.parent {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 10px 1fr 10px 1fr 10px 1fr 10px 1fr 10px 1fr 10px 1fr 10px 1fr 10px 1fr 10px 1fr 10px 1fr 10px 1fr;
    grid-template-columns: repeat(12, 1fr);
    -ms-grid-rows: 1fr 10px 1fr;
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;

}

.parent>*:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;

}

.parent>*:nth-child(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 3;

}

.parent>*:nth-child(3) {
    -ms-grid-row: 1;
    -ms-grid-column: 5;

}

.parent>*:nth-child(4) {
    -ms-grid-row: 1;
    -ms-grid-column: 7;

}

.parent>*:nth-child(5) {
    -ms-grid-row: 1;
    -ms-grid-column: 9;

}

.parent>*:nth-child(6) {
    -ms-grid-row: 1;
    -ms-grid-column: 11;

}

.parent>*:nth-child(7) {
    -ms-grid-row: 1;
    -ms-grid-column: 13;

}

.parent>*:nth-child(8) {
    -ms-grid-row: 1;
    -ms-grid-column: 15;

}

.parent>*:nth-child(9) {
    -ms-grid-row: 1;
    -ms-grid-column: 17;

}

.parent>*:nth-child(10) {
    -ms-grid-row: 1;
    -ms-grid-column: 19;

}

.parent>*:nth-child(11) {
    -ms-grid-row: 1;
    -ms-grid-column: 21;

}

.parent>*:nth-child(12) {
    -ms-grid-row: 1;
    -ms-grid-column: 23;

}

.parent>*:nth-child(13) {
    -ms-grid-row: 3;
    -ms-grid-column: 1;

}

.parent>*:nth-child(14) {
    -ms-grid-row: 3;
    -ms-grid-column: 3;

}

.parent>*:nth-child(15) {
    -ms-grid-row: 3;
    -ms-grid-column: 5;

}

.parent>*:nth-child(16) {
    -ms-grid-row: 3;
    -ms-grid-column: 7;

}

.parent>*:nth-child(17) {
    -ms-grid-row: 3;
    -ms-grid-column: 9;

}

.parent>*:nth-child(18) {
    -ms-grid-row: 3;
    -ms-grid-column: 11;

}

.parent>*:nth-child(19) {
    -ms-grid-row: 3;
    -ms-grid-column: 13;

}

.parent>*:nth-child(20) {
    -ms-grid-row: 3;
    -ms-grid-column: 15;

}

.parent>*:nth-child(21) {
    -ms-grid-row: 3;
    -ms-grid-column: 17;

}

.parent>*:nth-child(22) {
    -ms-grid-row: 3;
    -ms-grid-column: 19;

}

.parent>*:nth-child(23) {
    -ms-grid-row: 3;
    -ms-grid-column: 21;

}

.parent>*:nth-child(24) {
    -ms-grid-row: 3;
    -ms-grid-column: 23;

}

.div1 {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-area: 1 / 1 / 2 / 3;
}

.div2 {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 3;
    -ms-grid-column-span: 4;
    grid-area: 1 / 3 / 2 / 7;
}

.div3 {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 7;
    -ms-grid-column-span: 3;
    grid-area: 1 / 7 / 2 / 10;
}

.div4 {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 10;
    -ms-grid-column-span: 3;
    grid-area: 1 / 10 / 2 / 13;
}

.div5 {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
    grid-area: 2 / 1 / 3 / 4;
}

.div6 {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    -ms-grid-column: 4;
    -ms-grid-column-span: 4;
    grid-area: 2 / 4 / 3 / 8;
}

.div7 {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    -ms-grid-column: 8;
    -ms-grid-column-span: 3;
    grid-area: 2 / 8 / 3 / 11;
}

.div8 {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    -ms-grid-column: 11;
    -ms-grid-column-span: 2;
    grid-area: 2 / 11 / 3 / 13;
}


.the-optons {
    background: rgb(203, 173, 255);
    border-radius: 8px;
    height: 50px;
    margin-top: 10px;
    position: sticky;
    top: 10px;
    z-index: 99;
    overflow: hidden;
}

.incDec {
    background: rgba(255, 255, 255, 0.592);
    width: 84px;
    height: 100%;
    padding: 0 2px;
}

.incDec .name {
    height: 30%;
    font-family: var(--poppins);
    font-size: 12px;
    font-weight: 600;

}

.incDec .triggers {
    height: 70%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 5px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.incDec .triggers button {
    width: 25px;
    height: 25px;
    border: none;
    background: #8d76ffa5;
    border-radius: 4px;
    font-size: 20px;
    font-weight: 600;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: rgb(255, 255, 255);
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
}

.incDec .triggers button:hover {
    background: #8d76ff;
}

.incDec .triggers button:disabled {
    background: #626262;
}

.incDec .triggers .value {
    font-size: 20px;
    font-weight: 600;
    color: rgb(84, 3, 236);
}

.avop {
    color: rgb(109, 67, 193);
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 2px;
    position: relative;
}

.avop::before {
    content: '';
    height: 70%;
    width: 3px;
    border-radius: 3px;
    background: rgba(94, 69, 147, 0.526);
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    transition: 0.3s ease;
}

.g-images {
    display: -ms-grid;
    display: grid;
    gap: 10px;
    padding-top: 10px;
}

.g-images.c1 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
}

.g-images.c2 {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
}

.g-images.c3 {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
}

.g-images.c4 {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.g-image {
    aspect-ratio: 1.778;
    border-radius: 12px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    overflow: hidden;
    position: relative;
    -webkit-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    transition: 0.3s ease;
}

.g-image.dragged {
    scale: 0.8;
}

.g-image.hovered {
    scale: 1;
}

.g-images.dragging .g-image.hovered {
    scale: 1.1;
}

.brake {
    position: relative;
    font-size: 24px;
    color: #615d77;
    font-family: var(--poppins);
    padding: 20px 10px;
}

.brake::before {
    content: '';
    position: absolute;
    background: #615d7772;
    height: 2px;
    width: 70%;
    bottom: 10px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.g-image img {
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
    -o-object-position: center center;
    object-position: center center;
}

.g-image .index {
    border-radius: 30px;
    height: 20px;
    width: 20px;
    background: #8d76ff;
    color: white;
    position: absolute;
    top: 5px;
    font-size: 12px;
    font-weight: bold;
    left: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.g-image input[type="checkbox"] {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 20px;
    width: 20px;
    border: 1px solid #8d76ff;
    cursor: pointer;
}

.g-image input[type="checkbox"]:checked {
    background: #8d76ff;
}

.g-image .extra {
    position: absolute;
    bottom: -50px;
    max-width: 90%;
    cursor: pointer;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background: rgba(255, 255, 255, 0.8);
    padding: 10px 20px;
    color: #8d76ff;
    border-radius: 12px;
    -webkit-transition: 0.5s ease;
    -o-transition: 0.5s ease;
    transition: 0.5s ease;
    border: 2px solid transparent;
}

.g-image .extra:hover {
    background: white;
    border: 2px solid #8d76ff;
}

.g-image:hover .extra {
    bottom: 10px;
}


.save-changes {
    background: white;
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 8px;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    color: #8d76ff;
    -webkit-transition: 0.5s ease;
    -o-transition: 0.5s ease;
    transition: 0.5s ease;
}

.save-changes:hover {
    color: white;
    background: #8d76ff;
}

.cont {
    -webkit-transition: 1s ease;
    -o-transition: 1s ease;
    transition: 1s ease;
    display: -ms-grid;
    display: grid;
    -ms-grid-rows: 1fr;
    grid-template-rows: 1fr;
    overflow: hidden;
}

.cont.hidden {
    -ms-grid-rows: 0fr;
    grid-template-rows: 0fr;
}

.cont .child {
    min-height: 0;
    -webkit-transition: visibility 1s;
    -o-transition: visibility 1s;
    transition: visibility 1s;
    visibility: hidden;
}

.cont.visible .child {
    visibility: visible;
}

@media (max-width: 500px) {
    .the-optons {
        padding-left: 5px;
    }

    .avop {
        display: none;
    }

    .save-changes {
        font-size: 12px;
    }
}


.editorcards {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 10px;
    padding: 10px 0;
}

.editorcard {
    background: #ddd7ff;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    padding: 5px 10px;
}

.editorcard .corner {
    position: absolute;
    top: 0;
    right: 0;
    border-bottom-left-radius: 8px;
    background: #8d76ffb3;
    padding: 2px 5px;
    color: white;
    -webkit-box-shadow: -1px 0px 3px rgba(0, 0, 0, 0.262);
    box-shadow: -1px 0px 3px rgba(0, 0, 0, 0.262);
}

.editorcard .name {
    font-size: 22px;
    text-align: left;
    color: #725ed6;
}

.editorcard .username {
    font-size: 12px;
    text-align: left;
    color: #606060;
    font-weight: bold;
    padding: 2px 0;
    font-family: var(--poppins)
}

.editorcard .email {
    font-size: 14px;
    text-align: left;
    color: #606060;
    font-family: var(--poppins)
}

.editorcard .bt {
    font-family: var(--poppins);
    padding: 2px 10px;
    border-radius: 100px;
    -webkit-tap-highlight-color: transparent;
    -webkit-transition: 0.5s ease;
    -o-transition: 0.5s ease;
    transition: 0.5s ease;
    font-size: 12px;
    cursor: pointer;
}

.editorcard .bt.a {
    background: #ff5b9c;
    color: white;
}

.editorcard .bt.a:hover {
    background: white;
    color: #ff5b9c;
}

.editorcard .bt.d {
    background: #31543b;
    color: white;
}

.editorcard .bt.d:hover {
    background: white;
    color: #31543b;
}






.headNav {
    background: #ebebeb;
    min-height: 35px;
    border-radius: 12px;
    padding: 3px;
    z-index: 99;
}

.localNav {
    height: 100%;
}

.localNavLink {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    min-height: 30px;
    color: rgb(76, 76, 76);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    background: rgba(128, 128, 128, 0.237);
    border-radius: 8px;
    font-family: var(--poppins);
    -webkit-transition: 0.5s ease;
    -o-transition: 0.5s ease;
    transition: 0.5s ease;
    padding: 0 10px;
    -webkit-tap-highlight-color: transparent;
}

.localNavLink:hover {
    background: rgba(128, 128, 128, 0.584);
    color: rgb(237, 237, 237);
}

.localNavLink.active {
    font-size: 18px;
    background: #725ed6;
    color: #ffffff;
}

@media (max-width: 650px) {
    .localNav {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .localNavLink {
        font-size: 12px;
        font-family: var(--fira);
        padding: 0 5px;
    }

    .localNavLink.active {
        font-size: 14px;
    }

    .exceptionHead {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        gap: 5px;
    }
}