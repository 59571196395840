.about-us-header {
 padding-bottom: 20px;
}
.about-us-header h1 {
    font-size: 28px;
    
}
.about-us-section > h2, .mission-vision-section > h2 {
    color: rgb(78, 94, 211);
    padding-bottom: 10px;
    position: relative;
    width: max-content;
}
.about-us-section {
    padding-bottom: 20px;
}
.about-us-section > h2::before,  .mission-vision-section>h2::before {
    content: '';
    background: linear-gradient(to right, rgb(78, 94, 211) 60%, rgba(78, 94, 211, 0));
    width: 120%;
    height: 3px;
    position: absolute;
    bottom: 5px;
    left: 0px;
}
.about-us-section > p, .mission-vision-section>p {
    font-family: var(--poppins);
    font-size: 14px;
    word-spacing: 2px;
    letter-spacing: 0.5px;
    line-height: 186%;
}
.mission-vision-section {
    padding-bottom: 20px;
}
.mission-vision-section > p {
    padding-top: 10px;
}

.our-team {
    padding: 2rem;
    text-align: center;
    background-color: #f8f8f8;
}
.our-team > h2 {
    color: rgb(78, 94, 211);
    padding-bottom: 10px;
    position: relative;
    width: max-content;
    margin: 0 auto;
}
.our-team>h2::before {
    content: '';
    background: linear-gradient(to right, rgba(78, 94, 211, 0),rgb(78, 94, 211) 50%, rgba(78, 94, 211, 0));
    width: 120%;
    height: 3px;
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
}
.team-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.team-member {
    background: #fff;
    border-radius: 8px;
    padding: 1rem;
    margin: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 200px;
}

.team-member h3 {
    margin: 0.5rem 0;
    color: #333;
}

.team-member p {
    margin: 0.5rem 0;
    color: #777;
}

.team-member a {
    color: #1a0dab;
    text-decoration: none;
}

.social-links a {
    display: inline-block;
    margin: 0.5rem 0.5rem 0 0;
    color: #1a0dab;
    text-decoration: none;
}

.social-links a:hover {
    text-decoration: underline;
}