/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.departments {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 20px;
    padding: 20px 0;
}

.departments .dept {
    position: relative;
    background: rgb(64 50 104);
    color: white;
    border-radius: 12px;
    padding: 5px 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    -webkit-transition: 0.5s ease;
    -o-transition: 0.5s ease;
    transition: 0.5s ease;
}

.departments .dept:hover {
    background: rgb(85, 53, 139);
}

.departments .dept .float {
    font-size: 40px;
    font-weight: 900;
    letter-spacing: 2px;
}

.departments .dept .float.left {
    min-width: 150px;
    text-align: center;
    color: rgb(210, 202, 255);
}

.departments .dept .float.right {
    position: absolute;
    right: 10px;
    font-size: 3em;
    color: rgba(235, 220, 255, 0.385);
}

.dept .name {
    z-index: 9;
    font-size: 20px;
}

.dept .count {
    font-size: 14px;
}


@media (max-width: 700px) {
    .departments .dept {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    .departments .dept .float {
        font-size: 30px;
        font-weight: 900;
        letter-spacing: 2px;
    }

    .departments .dept .float.left {
        min-width: 70px;
        text-align: center;
        color: rgb(210, 202, 255);
    }

    .departments .dept .float.right {
        position: absolute;
        right: 10px;
    }

    .dept .name {
        z-index: 9;
        font-size: 20px;
    }

    .dept .count {
        font-size: 14px;
    }
}

@media (max-width: 400px) {
    .departments .dept>div {
        width: 100%;
        text-align: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .departments .dept .float.right {
        position: absolute;
        right: 10px;
    }
}


.subTitle {
    font-size: 14px;
    font-weight: bold;
    color: rgb(112, 112, 112);
}

.extraTitle {
    color: rgb(108, 106, 212);
}