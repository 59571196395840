/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.member-cards {
    padding-top: 20px;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 20px 1fr;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.member-card {
    min-height: 100px;
    background: rgba(255, 255, 255, 0.466);
    -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0);
    padding: 10px;
    border-radius: 8px;
    position: relative;
    border: 1px solid transparent;
    overflow: hidden;
    z-index: 9;
    text-align: left;
    font-family: var(--poppins);
    -webkit-transition: 0.5s ease;
    -o-transition: 0.5s ease;
    transition: 0.5s ease;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.member-card:hover {
    border: 1px solid rgba(128, 128, 128, 0.246);
    background: #ddd7ff;
    -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.164);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.164);
}

.member-card .name {
    font-size: 18px;
    font-weight: 600;
    color: rgb(103, 46, 217);
}

.member-card .achievement {
    font-size: 14px;
}

.member-card .designation {
    font-size: 12px;
    font-weight: 800;
    letter-spacing: 1px;
    color: rgb(83, 83, 83);
}

.member-card .username {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    color: rgb(83, 83, 83);
}

.member-card .email {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
    color: rgb(83, 83, 83);
}

.member-card .phone {}

.member-card-image {
    border-radius: 1000px;
    width: 100%;
    aspect-ratio: 1;
    border: 5px solid rgba(128, 128, 128, 0.365);
    overflow: hidden;
    background: rgba(255, 255, 255, 0.361);
}

.member-card-image img {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.member-card .department {
    position: absolute;
    right: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    border-radius: 4px;
    padding: 0px 10px;
    letter-spacing: 1px;
    font-size: 4em;
    font-weight: 800;
    font-family: var(--poppins);
    color: rgba(76, 86, 151, 0.278);
    z-index: -1;
    -webkit-filter: blur(1px);
    filter: blur(1px);
}

.extraOptions {
    height: 24px;
    width: 24px;
    position: absolute;
    top: 10px;
    right: 10px;
}

.extraOptions svg {
    height: 100%;
    width: 100%;
    background: rgba(190, 190, 190, 0.532);
    border-radius: 50px;
    padding: 2px;
    -webkit-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    transition: 0.3s ease;
    opacity: 0;
}

.member-card:hover .extraOptions svg {
    opacity: 1;
}

.extraOptions:hover svg {
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.688);
}

.extraOptions .ops {
    position: absolute;
    height: 0px;
    width: 100px;
    left: -105px;
    top: -5px;
    overflow: hidden;
    -webkit-transition: 0.5s ease;
    -o-transition: 0.5s ease;
    transition: 0.5s ease;
}

.extraOptions .ops .op {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 3px;
    width: 100%;
    background: rgb(165, 165, 165);
    padding: 3px;
    border-radius: 6px;
}

.extraOptions:hover .ops {
    height: 72px;
}

.button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 20px;
    width: 100%;
    font-size: 12px;
    border-radius: 4px;
}

.button.edit {
    background: rgb(128, 128, 235);
    color: white;
}

.button.add {
    background: rgb(58, 134, 154);
    color: white;
}

.button.delete {
    background: rgb(235, 72, 72);
    color: white;
}

.button.edit:hover {
    background: rgb(72, 72, 133);
    color: white;
}

.button.add:hover {
    background: rgb(36, 84, 96);
    color: white;
}

.button.delete:hover {
    background: rgb(138, 41, 41);
    color: white;
}

@media (max-width: 550px) {
    .member-cards {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    }
}