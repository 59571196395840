
.welcome-text {
    font-family: var(--poppins);
    font-weight: 500;
    text-align: center;
    color: rgb(142, 141, 199);
    text-transform: capitalize;
}
.logo {
    width: max-content;
    background: rgb(56,55,72);
    background: linear-gradient(310deg, rgb(47, 38, 161) 0%, rgb(146, 84, 253) 100%);
    background-clip: text;
    color: transparent;
    margin: 0 auto;
}
.logo h1 {
    font-size: 44px;
}
.logo h2 {
    text-transform: uppercase;
}
.logoImage {
    padding: 20px 0;
    width: 100%;
    min-width: 200px;
}
.logoImage img{
    width: 100%;
    filter: drop-shadow(0px 0px 23px rgba(0, 0, 0, 0.115));
}


form.form {
    max-width: 300px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
form.form > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: rgb(231, 219, 255);
    background: linear-gradient(112deg, rgb(207, 217, 255) 0%, rgb(217, 199, 255) 100%);
    padding: 10px;
    border-radius: 12px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.156);
}
form.form > div > label {
    color: midnightblue;
    font-weight: 600;
    font-family: var(--poppins);
}
form.form > div > input {
    border-radius: 6px;
    border: none;
    background: #f7f7f7;
    height: 40px;
    font-size: 18px;
    font-family: var(--poppins);
    padding: 10px ;
}
.submit {
    height: 36px;
    border-radius: 8px;
    padding: 0 20px;
    border: 2px solid rgb(124, 75, 215);
    background: transparent;
    text-transform: uppercase;
    font-family: var(--fira);
    font-size: 20px;
    letter-spacing: 1px;
    cursor: pointer;
    color: rgb(124, 75, 215);
    transition: 0.5s ease;
}
@media(max-width: 500px) {
    .submit{
        font-size: 14px;
    }
}
.submit:hover {
    background: rgb(124, 75, 215);
    color: white;
}

.pageHead {
    text-align: center;
    padding-top: 10px;
    font-family: var(--fira);
    padding-bottom: 20px;
    font-size: 32px;
    color: rgb(97, 78, 164);
}

.bigBtns {
    display: flex;
    padding: 20px 0;
    gap: 20px;
    flex-wrap: wrap;
}
.bigButton {
    background: rgb(127,100,185);
    background: linear-gradient(to right, rgba(127,100,185,1), rgba(127,100,185,1) 50%, rgb(192, 172, 222) 50%);
    flex: 1;
    background-size: 200% 100%;
    background-position: 100%;
    background-repeat: no-repeat;
    border-radius: 12px;
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: 0.7s ease;
    cursor: pointer;
}
.bigButton:hover {
    background-position: 0 100%;
}
.bigButton img {
    height: 50px;
    width: 50px;
    transition: 0.7s ease;
}
.bigButton p {
    color: transparent;
    
    background: rgb(127,100,185);
    background: linear-gradient(to left, rgb(90, 64, 147), rgb(78, 47, 145) 50%, rgb(234, 234, 234) 50%);
    font-size: 24px;
    background-size: 200% 100%;
    background-position: 100%;
    background-clip: text;
    font-weight: 600;
    
    transition: 0.7s ease;
}
.bigButton:hover img {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.484));
}
.bigButton:hover p {
    transform: scale(1.05);
    background-position: 0 100%;
}

.fpass {
    text-decoration: underline;
    text-align: center;
    display: block;
}