
.departments {

}
.dept-text {
    font-size: 20px;
    font-weight: 600;
}
.facultymember {
    min-height: 80px;
    height: max-content;
    background: rgb(88 85 178);
    box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.418);
    border-radius: 12px;
    display: flex;
    align-items: center;
    overflow: hidden;
    border: 2px solid transparent;
    cursor: pointer;
    padding-right: 10px;
    -webkit-tap-highlight-color: transparent;
}
.facultymember, .facultymember * {
    transition: 0.5s ease;
}
.facultymember:hover {
    background: rgb(241, 234, 255);
}
.facultymember.active:hover {
    background: rgb(248, 245, 255);
}
.facultymember:hover .name {
    color: rgb(158, 116, 255);
}
.facultymember:hover .des {
    color: rgb(105, 79, 221);
}
.facultymember.active:hover .des {
    color: rgba(142, 94, 237, 0.98);  
}
.facultymember.active:hover .name {
    color: rgb(138, 101, 222);
}
.facultymember .room {
    background: rgba(42, 70, 93, 0.563);
    padding: 5px 10px;
    border-radius: 4px;
    font-family: monospace;
    letter-spacing: 1px;
    color: aliceblue;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
}
.facultymember.active {
    padding: 20px 0;
    background: rgb(241, 234, 255);
    border: 2px solid rgba(103, 66, 196, 0.334);
}

.facultymember .name {
    font-size: 20px;
    color: white;
}
.facultymember .phone img {
    height: 25px;
}
.facultymember .email img {
    height: 30px;
}
.facultymember .phone, .facultymember .email {
    padding: 2px 5px;
    border-radius: 8px;
    color: rgb(45, 50, 65);
    transition: 0.5s ease;
    white-space: nowrap;
}
.facultymember .phone:hover, .facultymember .email:hover {
    background: #8965de48;
}
.facultymember .des {
    font-size: 14px;
    font-family: var(--poppins);
    font-weight: 600;
    color: white;
}
.transitionHeight {
    display: grid;
    grid-template-rows: 0fr;
}
.facultymember .afterActive {
    overflow: hidden;
    padding-right: 10px;
}
.facultymember.active .transitionHeight {
    grid-template-rows: 1fr;
}
.facultymember .achievement {
    font-size: 14px;
    font-family: var(--poppins);
    color: rgba(116, 116, 116, 0.98);
}
.facultymember .image {
    margin: 0 8px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s ease;
}

.facultymember .fg {
    flex: 6;
    transition: 0.5s ease;
}

.image .cont {
    width: 100%;
    max-width: 60px;
    aspect-ratio: 1;
    border-radius: 100px;
    transition: 0.5s ease;
}

.facultymember .image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.268));
}
.facultymember.active .image {
    flex: 2;
}
.facultymember.active .image .cont {
    max-width: 100px;
    border-radius: 12px;
}
.facultymember.active .name {
    font-size: 26px;
    color: rgb(138, 101, 222);
}
.facultymember.active .des {
    color: rgb(138, 101, 222);
}
.facultymember.active .achievement {
    display: block;
    visibility: visible;
    opacity: 1;
}

.break-word {
    word-break: break-all;
}

@media (max-width: 500px){
    
        .image .cont {
            max-width: 50px;
        }
    
        .facultymember.active .image .cont {
            max-width: 80px;
        }
}

@media (max-width: 390px){
    .facultymember.active .roomPhone {
        flex-wrap: wrap;
        align-items: center;
    }
}