/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.carouselCOntainer {
    border-radius: 12px;
    overflow: hidden;
    border: 2px solid rgb(146, 145, 255);
    background: rgb(146, 145, 255);
    background: -webkit-gradient(linear, left top, right top, color-stop(30%, rgba(146, 145, 255, 1)), color-stop(89%, rgba(130, 66, 201, 1)));
    background: -o-linear-gradient(left, rgba(146, 145, 255, 1) 30%, rgba(130, 66, 201, 1) 89%);
    background: linear-gradient(90deg, rgba(146, 145, 255, 1) 30%, rgba(130, 66, 201, 1) 89%);
    padding-bottom: 0.1px;
}

ul.thumbs {}

ul.thumbs li.thumb {
    height: 50px;
    aspect-ratio: 1.778;
}

.slider-frame {
    height: 600px;
    width: 100%;
    overflow: hidden;
    position: relative;
}
.slider {
    height: inherit;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.5s ease-in-out;
    will-change: transform;
    transform: translate3d(0px, 0px, 0px);
}
.slider-item {

}
.slider-item > img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
    transform: translateZ(0);
}
.arrPrev, .arrNext {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(108, 112, 226, 0.443);
    z-index: 9;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    transition: 0.4s ease;
}
.arrNext:hover, .arrPrev:hover {
    background: rgba(108, 112, 226, 0.977);
}
.arrPrev {
    left: 0;
}
.arrNext {
    right: 0;
}
 @media (max-width: 680px){
    .slider-frame {
        height: 300px;
    }
 }