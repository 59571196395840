/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.facultyBoxContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}
.facultyBox {
    background: rgba(118, 69, 155, 0.858);
    border-radius: 12px;
    position: relative;
    padding: 10px 15px;
    display: grid;
    place-items: center;
    aspect-ratio: 2;
    color: antiquewhite;
    background-position: center !important;
    font-weight: 600;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    background-size: 100% !important;
    text-align: center;
}

.facultyBox p:nth-child(1) {
    font-weight: 600;
    color: rgb(255, 255, 255);
    font-size: 24px;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.211);
    -webkit-transition: 0.5s ease;
    -o-transition: 0.5s ease;
    transition: 0.5s ease;
}

.facultyBox p:nth-child(2) {
    font-weight: 500;
    font-family: var(--poppins);
    color: rgb(255, 255, 255);
    font-size: 14px;
    -webkit-transition: 0.5s ease;
    -o-transition: 0.5s ease;
    transition: 0.5s ease;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.455);
}

.image {
    height: 50px;
    aspect-ratio: 1;
    width: 50px;
}

.image img {
    height: 50px;
    width: 50px;
    -webkit-filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.352));
    filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.352));
}







.facultyBox:hover p:nth-child(1) {
    color: rgb(241, 235, 255);
}

.facultyBox:hover p:nth-child(2) {
    color: rgb(183, 183, 183);
}

.facultyBox:hover {
    background-size: 110% !important;
}

.facultyBox .flot {
    position: absolute;
    top: 5px;
    left: 10px;
    font-family: var(--poppins);
    font-weight: 500;
    font-size: 12px;
    color: rgb(253, 250, 255);
}


@media(max-width: 650px) {
    .facultyBoxContainer {
            display: grid;
            grid-template-columns: 1fr;
            gap: 20px;
        }
        .facultyBox {
            aspect-ratio: 3;
            font-size: 1rem;
        }
    .facultyBox p:nth-child(1) {
        font-size: 20px;
    }

    .facultyBox p:nth-child(2) {
        font-size: 12px;
    }
}